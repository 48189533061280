import Vue from "vue";

import * as Sentry from "@sentry/vue";

// Sentry integration init
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://dc43b3ef96809627599001378761afc8@sentry.projectcece.com/4"
  });
  Sentry.setUser({ ip_address: "{{auto}}" });
}

import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./services/api.service";
import JwtService from "@/services/jwt.service";
import { LOGOUT } from "./store/auth.module";
import {
  GET_ME,
  GET_PUBLISHER_DATA,
  GET_STORE_DATA
} from "./store/user.module";
import config from "./config";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "./plugins/vue-i18n";
import VueMatomo from "vue-matomo";
import "./plugins/bootstrap-vue";
import "./plugins/perfect-scrollbar";
import "./plugins/highlight-js";
import "./plugins/inline-svg";
import "./plugins/metronic";
import "./plugins/click-outside";

// API service init
ApiService.init();

// If accesstoken exists set header
if (JwtService.getToken()) {
  ApiService.mount401Interceptor();
}

router.beforeEach((to, from, next) => {
  const isPublicRoute = to.matched.some(record => record.meta.public);
  const webshopUserOnlyRoute = to.matched.some(
    record => record.meta.webshopUserOnly
  );
  const publisherUserOnlyRoute = to.matched.some(
    record => record.meta.publisherUserOnly
  );
  const unauthenticatedOnlyRoute = to.matched.some(
    record => record.meta.unauthenticatedOnly
  );
  const isAuthenticated = !!JwtService.getToken();

  // Somehow logged in without a user id, log out
  // can happen because of how the store saves data
  // in localStorage
  if (isAuthenticated && !store.state.user.user.id) {
    store.dispatch(LOGOUT);
    return next("/");
  }

  // If not a public page and not logged in, redirect to login page
  if (!isPublicRoute && !isAuthenticated && to.name != "login") {
    return next({
      path: "/login/",
      query: { redirect: to.fullPath }
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (unauthenticatedOnlyRoute && isAuthenticated) {
    return next("/");
  }

  // Dissallow webshop users to visit publisher routes and visa versa
  if (webshopUserOnlyRoute && !store.getters.isWebshopUser) {
    return next("/");
  }
  if (publisherUserOnlyRoute && !store.getters.isPublisherUser) {
    return next("/");
  }

  // Show publisher onboarding as long as a publisher user doesn't have a
  // publisher linked to its account
  if (
    isAuthenticated &&
    store.getters.isPublisherUser &&
    !store.getters.hasPublisherData &&
    !to.path.includes("/p/onboarding") &&
    !to.path.includes("/p/settings/user/confirm-email")
  ) {
    if (!store.state.user.user.firstName) {
      return next("/p/onboarding/");
    }
    return next("/p/onboarding/publisher/");
  }

  // Show store onboarding as long as a store user doesn't have a
  // store linked to its account
  if (
    isAuthenticated &&
    store.getters.isWebshopUser &&
    !store.getters.hasCompleteStoreProfile &&
    !to.path.includes("/w/onboarding") &&
    !to.path.includes("/w/settings/user/confirm-email") &&
    !to.path.includes("/w/settings/finish-setup-payment") &&
    !to.path.includes("/w/settings/setup-payment") &&
    !to.path.includes("/docs/") && !to.path.includes("/terms-and-conditions/")
  ) {
    if (!store.state.user.user.firstName) {
      return next("/w/onboarding/");
    } else if (!store.state.user.store.id) {
      return next("/w/onboarding/store/");
    } else if (store.getters.invitedByPublisher && !store.getters.membership_plan) {
      return next("/w/onboarding/plan/");
    } else {
      return next("/w/onboarding/next-steps/");
    }
  }

  // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  next();
});

if (config.matomo.enabled) {
  Vue.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: config.matomo.host,
    siteId: config.matomo.siteId,

    // Changes the default .js and .php endpoint's filename
    // Default: 'matomo'
    trackerFileName: config.matomo.trackerFileName,

    // Overrides the autogenerated tracker endpoint entirely
    // Default: undefined
    // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

    // Overrides the autogenerated tracker script path entirely
    // Default: undefined
    // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

    // Enables automatically registering pageviews on the router
    router: router,

    // Enables link tracking on regular links. Note that this won't
    // work for routing links (ie. internal Vue router links)
    // Default: true
    enableLinkTracking: true,

    // Require consent before sending tracking information to matomo
    // Default: false
    requireConsent: false,

    // Whether to track the initial page view
    // Default: true
    trackInitialView: true,

    // Run Matomo without cookies
    // Default: false
    disableCookies: false,

    // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
    // Default: false
    enableHeartBeatTimer: false,

    // Set the heartbeat timer interval
    // Default: 15
    heartBeatTimerInterval: 15,

    // Whether or not to log debug information
    // Default: false
    debug: false,

    // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
    // Default: undefined
    userId: undefined,

    // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
    // Default: undefined, example '*.example.com'
    cookieDomain: undefined,

    // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
    // Default: undefined, example: '*.example.com'
    domains: undefined,

    // A list of pre-initialization actions that run before matomo is loaded
    // Default: []
    // Example: [
    //   ['API_method_name', parameter_list],
    //   ['setCustomVariable','1','VisitorType','Member'],
    //   ['appendToTrackingUrl', 'new_visit=1'],
    //   etc.
    // ]
    preInitActions: []
  });
}

// Refresh user data and user publisher/webshop
// data when user revists/refreshes the page
(async function () {
  if (store.getters.isAuthenticated) {
    try {
      await store.dispatch(GET_ME);
      if (store.getters.isPublisherUser && store.state.user.publisher.id) {
        store.dispatch(GET_PUBLISHER_DATA);
      } else if (store.state.user.store.id) {
        store.dispatch(GET_STORE_DATA);
      }
    } catch (err) {
      console.error("Error fetch GET_ME after page load", err);
    }
  }
})();

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
});

app.$mount("#app");

// Only available during E2E tests
if (window.Cypress) {
  window.app = app;
}
